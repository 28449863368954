<template>
    <b-row>
      <b-col cols="12" v-if="can('read/pengelolaan-anggaran/regional')">
        <b-card title="Pengelolaan Anggaran Regional">
          <b-row class="pb-2">
            <b-col lg="12" xs="12" class="mb-1">
              <b-button
                v-if="can('store/pengelolaan-anggaran/regional')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="ml-0"
                @click="gotoCreatePage()"
              >
                Tambah
              </b-button>
            </b-col>
            <b-col lg="4" md="6" xs="12" class="mb-1">
              Periode
              <DatePicker
                v-model="dateRange"
                range
                format="DD-MM-YYYY"
                placeholder="Select Date Range"
                :unlink-panels="true"
                class="w-100"
                @change="handleSearch"
              />
            </b-col>
            <b-col lg="4" md="6" xs="12" class="mb-1">
              <b-input-group size="8">
                Regional
                <v-select
                  id="status"
                  v-model="selectedRegional"
                  label="text"
                  placeholder="Cari Regional"
                  :options="selectedRegionalOptions"
                  class="form-select w-100"
                  v-on:change="handleSearch"
                />
              </b-input-group>
            </b-col>
            <b-col lg="4" md="12" xs="12">
              <b-form @submit.prevent="handleSearch">
                Pencarian
                <b-input-group size="8">
                  <b-form-input
                    id="filterInput"
                    v-model="search"
                    type="search"
                    :placeholder="'Cari disini...'"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!search" @click="search = ''">
                      Hapus
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form>
            </b-col>
          </b-row>
          <b-table
            ref="tableFe"
            striped
            hover
            responsive
            show-empty
            class="position-relative"
            :perPage="perPage"
            :currentPage="currentPage"
            :totalRows="totalRows"
            :fields="fields"
            :items="getItems"
            :sort-by.sync="sortBy"
            @changePerPage="changePerPage"
            @changeCurrentPage="changeCurrentPage"
          >
            <template #table-busy>
              <div class="text-center my-3">
                <div>Loading data, please wait...</div>
              </div>
            </template>
            <template #empty>
              <div class="text-center my-2">Data tidak tersedia</div>
            </template>
            <template #emptyfiltered>
              <div class="text-center my-2">Data tidak tersedia</div>
            </template>
            <template #cell(no)="data">
              {{ (currentPage - 1) * perPage + (data.index + 1) }}
            </template>
            <template #cell(anggaran)="data">
              <span class="text-capitalize d-block" style="text-align: end;">{{
                data.item.anggaran | currency
              }}</span>
            </template>
            <template #cell(start_date)="data">
              <span class="text-capitalize d-block">{{
                data.item.start_date | moment('DD-MM-YYYY')
              }}</span>
            </template>
            <template #cell(end_date)="data">
              <span class="text-capitalize d-block">{{
                data.item.end_date | moment('DD-MM-YYYY')
              }}</span>
            </template>
            <!-- A virtual column -->
            <template #cell(Aksi)="data">
              <div style="min-width: 100px">
                <b-button
                  v-if="can('show/pengelolaan-anggaran/regional')"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  v-b-popover.hover.bottom="'Detail'"
                  size="sm"
                  class="btn-icon float-sm-left mr-1"
                  @click="gotoDetailPage(data.item.id)"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
                <b-button
                  v-if="can('edit/pengelolaan-anggaran/regional')"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  v-b-popover.hover.bottom="'Ubah'"
                  size="sm"
                  class="btn-icon float-sm-left mr-1"
                  @click="gotoEditPage(data.item.id)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  v-if="can('delete/pengelolaan-anggaran/regional')"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  v-b-popover.hover.bottom="'Hapus'"
                  size="sm"
                  class="btn-icon float-sm-left mr-1"
                  @click="confirmDelete(data.item.id)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
            </template>
          </b-table>
          <span v-if="totalRows == 1" style="margin: 1rem"></span>
          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  @change="changePerPage()"
                  size="sm"
                  inline
                  :options="pageOptions"
              />
            </b-form-group>

            <div>
              Menampilkan {{ from }} hingga {{ to }} dari {{ totalRows }} entri
            </div>
            
            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              />
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col v-else>
        <b-card>
          <div class="col-12 p-2 p-sm-3 mb-4 mt-3">
            <div class="w-100 text-center">
              <h2 class="mb-1">
                  Tidak Memiliki Akses
              </h2>
              <p class="mb-2">
                  Anda tidak memiliki akses pada halaman ini
              </p>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </template>
  
  <style>
  .width160px {
    width: 160px !important;
    background-color: "red !important";
  }
  .vs__dropdown-toggle, .vs__selected {
    max-width: none !important;
  }
  </style>
  
  <script>
  import {
    BRow,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BCol,
    BModal,
    VBModal,
    BForm,
    BFormRadioGroup,
    BFormTextarea,
    VBPopover,
    BFormCheckbox,
  } from 'bootstrap-vue';
  import vSelect from "vue-select";
  import BCardCode from "@core/components/b-card-code";
  import axios from "@axios";
  import Ripple from "vue-ripple-directive";
  import DatePicker from "vue2-datepicker";
  import moment from 'moment'
  import "vue2-datepicker/index.css";
  
  export default {
    name: "UsersList",
    components: {
      BRow,
      BCol,
      BCard,
      BTable,
      BAvatar,
      BBadge,
      BFormGroup,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
      BCardBody,
      BDropdown,
      BDropdownItem,
      BDropdownDivider,
      vSelect,
      BModal,
      BCardCode,
      BForm,
      BFormRadioGroup,
      BFormTextarea,
      BFormCheckbox,
    },
    directives: {
      Ripple,
      "b-popover": VBPopover
    },
    data() {
      const currentYear = new Date().getFullYear();
      const startOfYear = new Date(currentYear, 0, 1); // 1 Januari
      // const startOfYear = new Date();
      const endOfYear = new Date(currentYear, 11, 31); // 31 Desember
      return {
        config: {
          api: "/budgets",
          regional: '/misc/regionals',
        },
        search: '',
        perPage: 10,
        currentPage: 1,
        totalRows: 10,
        from: 1,
        to: 10,
        sortBy: 'id',
        pageOptions: [1, 10, 25, 50, 100],
        fields: [
          "no",
          { key: "anggaran", label: "Anggaran" },
          { key: "name_regional", label: "Regional" },
          { key: "start_date", label: "Periode Awal", thStyle: 'max-width:300px' },
          { key: "end_date", label: "Periode Akhir", thStyle: 'max-width:300px' },
          { key: 'Aksi', label: 'Aksi', thStyle: 'width:250px', stickyColumn: true, class: 'sticky-column pr-1'}
        ],
        items: [],
        selectedRegional: {
          text: "Pilihan Semua Regional",
          value: "0"
        },
        selectedRegionalOptions: [],
        dateRange: [startOfYear, endOfYear],
      };
    },
    watch: {
      search: function () {
        this.$refs.tableFe.refresh()
      },
      selectedRegional: function () {
        this.$refs.tableFe.refresh()
      }
    },
    mounted() {
      let _ = this;
      _.getRegionalList();
      if (_.$route.params) {
        if (_.$route.params.event) {
          _.makeToast(
            _.$route.params.event,
            _.$route.params.title,
            _.$route.params.text
          );
        }
      }
    },
    methods: {
      gotoCreatePage() {
        let _ = this;
        _.$router.push("/pengelolaan-anggaran/regional/add");
      },
      gotoDetailPage(id) {
        let _ = this;
        _.$router.push("/pengelolaan-anggaran/regional/" + id + "/detail");
      },
      gotoEditPage(id) {
        let _ = this;
        _.$router.push("/pengelolaan-anggaran/regional/" + id + "/edit");
      },
      getRegionalList() {
        axios.get(this.config.regional).then(response => {
          // this.regionalLists = response.data.data.data
          var header = [{
            text: "Pilihan Semua Regional",
            value: "0"
          }];
          var options = response.data.data.data.map(item => ({
            text: item.nmwilayah,
            value: item.idwilayah
          }));

          this.selectedRegionalOptions = [...header, ...options];
        })
        .catch(error => {
          console.error(error)

          var message = "";
          if(typeof error.response.data.message === 'object'){
            message = error.response.data.message.errors[0].msg
          } else {
            message = error.response.data.message
          }
          this.makeToast('danger', "Terjadi Kesalahan", message)
        });
      },
      confirmDelete(id) {
        this.$swal({
          title: 'Apakah Anda Yakin',
          text: 'untuk menghapus data?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Batal',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            axios
              .delete(`${this.config.api}/${id}`)
              .then(response => {
                this.$swal({
                  icon: 'success',
                  title: 'Berhasil Dihapus',
                  text: response.data.data.messages,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.$refs.tableFe.refresh()
              })
              .catch(error => {
                var message = "";
                if(typeof error.response.data.message === 'object'){
                  message = error.response.data.message.errors[0].msg
                } else {
                  message = error.response.data.message
                }
                this.$swal({
                  icon: 'Danger',
                  title: 'Terjadi kesalahan!',
                  text: message,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          }
        })
      },
      // handleSearch() {
      //   this.$refs.tableFe.refresh()
      // },
      getItems(ctx, callback) {
        const _ = this
  
        let endpoint = `${_.config.api}/list/regional?per_page=${_.perPage}&page=${_.currentPage}`
        if (_.search) {
          endpoint += `&key=${_.search}`
        }
        
        if(_.selectedRegional) {
          endpoint +=  `&regional=${_.selectedRegional.value}`
        }

        if (_.dateRange.length > 0) {
          // console.log(_.dateRange);

          const currentYear = new Date().getFullYear();
          const stDate = _.dateRange[0] ?? new Date(currentYear, 0, 1);
          const edDate = _.dateRange[1] ?? new Date(currentYear, 11, 31);
          
          var startDate = moment(stDate).format('YYYY-MM-DD');
          var endDate = moment(edDate).format('YYYY-MM-DD');
          // console.log(startDate, endDate);
          // endpoint += `&start_date=${_.dateRange[0]}&end_date=${_.dateRange[1]}`
          endpoint += `&start_date=${startDate}&end_date=${endDate}`
        }

        axios
          .get(endpoint)
          .then(resp => {
            const consume = resp.data.data
            // console.log(consume);
            
            _.perPage = consume.per_page
            _.currentPage = consume.current_page
            _.totalRows = consume.total
            _.from = consume.from ?? 0
            _.to = consume.to ?? 0
            callback(consume.data || [])
          })
          .catch(error => {
            console.error(error)

            var message = "";
            if(typeof error.response.data.message === 'object'){
              message = error.response.data.message.errors[0].msg
            } else {
              message = error.response.data.message
            }
            this.makeToast('danger', "Terjadi Kesalahan", message)
          })
        return null
      },
      handleSearch() {
        this.$refs.tableFe.refresh()
      },
      changePerPage() {
        const _ = this
        _.$refs.tableFe.refresh()
      },
      changeCurrentPage(currentPage) {
        let _ = this;
        _.currentPage = currentPage;
      },
      makeToast(variant = null, title = "Title here...", text = "Text here...") {
        this.$bvToast.toast(text, {
          title,
          variant,
          solid: true,
        });
      },
    },
  };
  </script>